import { Route, Routes } from "react-router-dom";
import SalesForecast from "./SalesForcast/SalesForecast";
import BrandConfiguration from "./BrandConfiguration/BrandConfiguration";
import DailyInventoryLevels from "./DailyInventoryLevels/DailyInventoryLevels";
import ReplacementPlan from "./ReplenishmentPlan/ReplenishmentPlan";
import AdhocReplenishment from "./AdhocReplenishments/AdhocReplenishments";
import OutOfStockLoss from "./OutOfStockLoss/OutOfStockLoss";
import WorkingCapital from "./InventoryCapital/InventoryCapital";
import Reports from "./Reports/Reports";
import DailySalesInventory from "./DailySalesInventory/DailySalesInventory";
import Signin from "./Signin/Signin";
import PurchaseOrders from "./PurchaseOrders/PurchaseOrders";
import PrivateRoute from "../Components/PrivateRoute/PrivateRoute";
import Product from "./Product/Product";
import Channel from "./Channel/Channel";
import PlannedVsActual from "./Reports/PlannedVsActual/PlannedVsActual";
import InventoryHealth from "./Reports/InventoryHealth/InventoryHealth";
import Classification from "./Classification/Classification";
import InventoryScore from "./InventoryScore/InventoryScore";
import DrrUpdate from "./DrrUpdate/DrrUpdate";
import BrandOverview from "./BrandOverview/BrandOverview";

const Main = ({ token }) => {
  return (
    <Routes>
      <Route path="/sign-in" element={<Signin />} />
      <Route path="/" element={<PrivateRoute token={token} />}>
        <Route path="/" element={<BrandOverview />} />
        <Route path="/sales/forecast" element={<SalesForecast />} />
        <Route path="/brand-configuration" element={<BrandConfiguration />} />
        <Route path="/products" element={<Product />} />
        <Route path="/channels" element={<Channel />} />
        <Route
          path="/daily-inventory-levels"
          element={<DailyInventoryLevels />}
        />
        <Route
          path="/daily-sales-inventory"
          element={<DailySalesInventory />}
        />
        <Route path="/drr-update" element={<DrrUpdate />} />
        <Route path="/replenishment/planned" element={<ReplacementPlan />} />
        <Route path="/replenishment/adhoc" element={<AdhocReplenishment />} />
        <Route path="/purchase-orders" element={<PurchaseOrders />} />
        <Route path="/inventory-score" element={<InventoryScore />} />
        <Route path="/out-of-stock-loss" element={<OutOfStockLoss />} />
        <Route path="/inventory-capital" element={<WorkingCapital />} />
        {/* <Route path="/reports" element={<Reports />} /> */}
        <Route path="/planned-vs-actual" element={<PlannedVsActual />} />
        <Route path="/inventory-health" element={<InventoryHealth />} />
        <Route path="/classifications" element={<Classification />} />
      </Route>
    </Routes>
  );
};

export default Main;
