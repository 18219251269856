import { AgGridReact } from "@ag-grid-community/react";
import { Box, Button, Paper, TextField } from "@mui/material";
import styles from "./Styles.module.css";
import { useCallback, useMemo, useRef } from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import { monthNames } from "../../Utils/helperFunctions";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const BrandOverviewTable = ({ loading, tableData }) => {
  const gridRef = useRef();
  const rows = [
    { id: "inventory_norm", value: "Inventory Norm Units" },
    { id: "opening_inventory", value: "Opening inventory (Units)" },
    { id: "sales", value: "Sales (unit)" },
    { id: "inward_stock", value: "Inward stock (Units)" },
    { id: "closing_inventory", value: "Closing Inventory" },
    { id: "safety_stock", value: "Safety Stock" },
  ];
  const columns = [
    {
      field: "value",
      headerName: "",
      minWidth: 175,
      flex: 2,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    ...tableData.map((data) => {
      return {
        field: `${monthNames[data.month]}_${data.year}`,
        headerName: `${monthNames[data.month]} ${data.year}`,
        minWidth: 175,
        flex: 2,
        cellStyle: { textAlign: "center" },
      };
    }),
  ];

  rows.forEach((row) => {
    tableData.forEach((data) => {
      row[`${monthNames[data.month]}_${data.year}`] = data[row.id]
        ? Math.round(data[row.id])
        : data[row.id];
    });
  });

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Sales Forecast(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{ height: rows.length < 17 ? "" : "700px", margin: "2em 0" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 17 ? "autoHeight" : null}
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          rowHeight={35}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
          getRowStyle={(params) => {
            if (params.data.id === "total") {
              return {
                background: "#f2f1ed",
                fontSize: "15px",
                fontWeight: "bold",
              };
            }
            return null;
          }}
        />
      )}
    </Paper>
  );
};

export default BrandOverviewTable;
