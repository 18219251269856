import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import StorageIcon from "@mui/icons-material/Storage";
import ReportIcon from "@mui/icons-material/Report";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import WarningIcon from "@mui/icons-material/Warning";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import InventoryIcon from "@mui/icons-material/Inventory";
import BalanceIcon from "@mui/icons-material/Balance";
import RepartitionIcon from "@mui/icons-material/Repartition";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ScoreIcon from "@mui/icons-material/Score";
import SourceIcon from "@mui/icons-material/Source";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import image from "../../Assets/Images/gbl.png";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Box } from "@mui/system";
import AppName from "../AppName/AppName";
import { logout } from "../../Utils/helperFunctions";
import { toast } from "react-toastify";
import context from "../../Contexts/context";

const MenuBar = () => {
  const navigate = useNavigate();
  const { setToken } = useContext(context);
  const { pathname } = useLocation();
  const [openReportList, setOpenReportList] = useState(
    pathname === "/planned-vs-actual" ||
      pathname === "/inventory-health" ||
      pathname === "/out-of-stock-loss" ||
      pathname === "/inventory-capital"
  );
  const [openReplenishment, setOpenReplenishment] = useState(
    pathname === "/replenishment/planned" || pathname === "/replenishment/adhoc"
  );

  const handleOpenReportList = () => {
    if (
      pathname !== "/planned-vs-actual" &&
      pathname !== "/inventory-health" &&
      pathname !== "/out-of-stock-loss" &&
      pathname !== "/inventory-capital"
    ) {
      setOpenReportList(!openReportList);
    }
  };

  const handleOpenReplenishment = () => {
    if (
      pathname !== "/replenishment/planned" &&
      pathname !== "/replenishment/adhoc"
    ) {
      setOpenReplenishment(!openReplenishment);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.data.message === "OK" || response.status === 200) {
        toast("Logout successfully");
        localStorage.removeItem("token");
        setToken(false);
        navigate("/sign-in");
      } else {
        console.log("Unable to logout: ", response);
      }
    } catch (error) {
      console.log("Error while logging out: ", error);
    }
  };

  return (
    <Box>
      <List className={styles.list}>
        <AppName />
        <Divider />
        <ListItemButton
          sx={{ font: "small-caption" }}
          className={
            pathname === "/"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/")}
        >
          <ListItemIcon>
            <SourceIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/" && "bold",
            }}
            primary={"Brand Overview"}
          />
        </ListItemButton>
        <ListItemButton
          sx={{ font: "small-caption" }}
          className={
            pathname === "/sales/forecast"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/sales/forecast")}
        >
          <ListItemIcon>
            <AssessmentIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/sales/forecast" && "bold",
            }}
            primary={"Sales Forecast Input"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/brand-configuration"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/brand-configuration")}
        >
          <ListItemIcon>
            <SettingsIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/brand-configuration" && "bold",
            }}
            primary={"Brand Configuration"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/products" || pathname === "/classifications"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/products")}
        >
          <ListItemIcon>
            <ProductionQuantityLimitsIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/products" && "bold",
            }}
            primary={"Products"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/channels"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/channels")}
        >
          <ListItemIcon>
            <AcUnitIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/channels" && "bold",
            }}
            primary={"Channels"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/daily-inventory-levels"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/daily-inventory-levels")}
        >
          <ListItemIcon>
            <ShowChartIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/daily-inventory-levels" && "bold",
            }}
            primary={"Daily Inventory Levels"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/daily-sales-inventory"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/daily-sales-inventory")}
        >
          <ListItemIcon>
            <EqualizerIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/daily-sales-inventory" && "bold",
            }}
            primary={"Daily Sales"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/drr-update"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/drr-update")}
        >
          <ListItemIcon>
            <SystemUpdateAltIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/drr-update" && "bold",
            }}
            primary={"DRR Update"}
          />
        </ListItemButton>
        <ListItemButton
          className={
            pathname === "/purchase-orders"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/purchase-orders")}
        >
          <ListItemIcon>
            <ShoppingCartIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/purchase-orders" && "bold",
            }}
            primary={"Purchase Orders"}
          />
        </ListItemButton>
        <ListItemButton
          sx={{ font: "small-caption" }}
          className={
            pathname === "/inventory-score"
              ? styles.activeListItemButton
              : styles.listItemButton
          }
          onClick={() => navigate("/inventory-score")}
        >
          <ListItemIcon>
            <ScoreIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight: pathname === "/inventory-score" && "bold",
            }}
            primary={"Inventory Score"}
          />
        </ListItemButton>
        <ListItemButton
          onClick={handleOpenReplenishment}
          className={styles.listItemButton}
        >
          <ListItemIcon>
            <LocalShippingIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight:
                (pathname === "/replenishment/planned" ||
                  pathname === "/replenishment/adhoc") &&
                "bold",
            }}
            primary={"Replenishments"}
          />
          {openReplenishment ? (
            <ExpandLess className={styles.listItemIcon} />
          ) : (
            <ExpandMore className={styles.listItemIcon} />
          )}
        </ListItemButton>
        <Collapse in={openReplenishment} timeout={"auto"} unmountOnExit>
          <ListItemButton
            sx={{ pl: 4 }}
            className={
              pathname === "/replenishment/planned"
                ? styles.activeListItemButton
                : styles.listItemButton
            }
            onClick={() => navigate("/replenishment/planned")}
          >
            <ListItemIcon>
              <RepartitionIcon className={styles.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              className={styles.listItemText}
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: pathname === "/replenishment/planned" && "bold",
              }}
              primary={"Replenishment Plan"}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            className={
              pathname === "/replenishment/adhoc"
                ? styles.activeListItemButton
                : styles.listItemButton
            }
            onClick={() => navigate("/replenishment/adhoc")}
          >
            <ListItemIcon>
              <StorageIcon className={styles.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              className={styles.listItemText}
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: pathname === "/replenishment/adhoc" && "bold",
              }}
              primary={"Adhoc Replenishments"}
            />
          </ListItemButton>
        </Collapse>
        <ListItemButton
          onClick={handleOpenReportList}
          className={styles.listItemButton}
        >
          <ListItemIcon>
            <ReportIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
              fontWeight:
                (pathname === "/out-of-stock-loss" ||
                  pathname === "/inventory-capital" ||
                  pathname === "/planned-vs-actual" ||
                  pathname === "/inventory-health") &&
                "bold",
            }}
            primary={"Reports"}
          />
          {openReportList ? (
            <ExpandLess className={styles.listItemIcon} />
          ) : (
            <ExpandMore className={styles.listItemIcon} />
          )}
        </ListItemButton>
        <Collapse in={openReportList} timeout="auto" unmountOnExit>
          <ListItemButton
            sx={{ pl: 4 }}
            className={
              pathname === "/out-of-stock-loss"
                ? styles.activeListItemButton
                : styles.listItemButton
            }
            onClick={() => navigate("/out-of-stock-loss")}
          >
            <ListItemIcon>
              <WarningIcon className={styles.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              className={styles.listItemText}
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: pathname === "/out-of-stock-loss" && "bold",
              }}
              primary={"Out Of Stock Loss"}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            className={
              pathname === "/inventory-capital"
                ? styles.activeListItemButton
                : styles.listItemButton
            }
            onClick={() => navigate("/inventory-capital")}
          >
            <ListItemIcon>
              <CurrencyRupeeIcon className={styles.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              className={styles.listItemText}
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: pathname === "/inventory-capital" && "bold",
              }}
              primary={"Inventory Capital"}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            className={
              pathname === "/planned-vs-actual"
                ? styles.activeListItemButton
                : styles.listItemButton
            }
            onClick={() => navigate("/planned-vs-actual")}
          >
            <ListItemIcon>
              <BalanceIcon className={styles.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              primary="Planned vs Actual"
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: pathname === "/planned-vs-actual" && "bold",
              }}
              className={styles.listItemText}
            />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            className={
              pathname === "/inventory-health"
                ? styles.activeListItemButton
                : styles.listItemButton
            }
            onClick={() => navigate("/inventory-health")}
          >
            <ListItemIcon>
              <InventoryIcon className={styles.listItemIcon} />
            </ListItemIcon>
            <ListItemText
              primary="Inventory Health"
              primaryTypographyProps={{
                fontSize: "14px",
                fontWeight: pathname === "/inventory-health" && "bold",
              }}
              className={styles.listItemText}
            />
          </ListItemButton>
        </Collapse>
        <ListItemButton
          className={styles.listItemButton}
          onClick={() => {
            handleLogout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon className={styles.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            className={styles.listItemText}
            primaryTypographyProps={{
              fontSize: "14px",
            }}
            primary={"Signout"}
          />
        </ListItemButton>
        <Divider />
      </List>
      {/* <Box className={styles.imageDiv}>
        <img className={styles.gblIcon} src={image} alt="Goat Brand Lab" />
      </Box> */}
    </Box>
  );
};

export default MenuBar;
