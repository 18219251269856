import React, { useEffect, useState } from "react";
import styles from "./DrrUpdate.module.css";
import DrrUpdateForm from "../../Components/DrrUpdate/DrrUpdateForm";
import DrrUpdateTable from "../../Components/DrrUpdate/DrrUpdateTable";
import {
  fetchChannels,
  fetchClassification,
  fetchDailySalesForecastDrr,
  updateSalesForecastDrr,
} from "../../Utils/helperFunctions";
import { toast } from "react-toastify";
import DrrUpdateByCsvForm from "../../Components/DrrUpdate/DrrUpdateByCsvForm";

const DrrUpdate = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [salesForecast, setSalesForecast] = useState([]);
  const [filteredSalesForecast, setFilteredSalesForecast] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [updatedSalesForecast, setUpdatedSalesForecast] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [classifications, setClassifications] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [openDrrUpdateForm, setOpenDrrUpdateForm] = useState(false);
  const [drrUpdateCsvFile, setDrrUpdateCsvFile] = useState(null);

  const getChannels = async () => {
    setLoading(true);
    const postData = { brand: brandName };
    try {
      const response = await fetchChannels(postData);
      if (response.status === 200) {
        setChannels(
          response.data.channels.map((channel) => {
            return { value: channel.name, label: channel.name, id: channel.id };
          })
        );
        if (response.data.channels.length) {
          setSelectedChannel({
            value: response.data.channels[0].name,
            label: response.data.channels[0].name,
            id: response.data.channels[0].id,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("Unable to get channels for the brand", error);
      setLoading(false);
    }
  };

  const getClassification = async () => {
    setSelectedClassifications([]);
    const postData = { brand: brandName };
    try {
      const response = await fetchClassification(postData);
      setClassifications(
        response.data.classifications.map((value) => {
          return { value: value.title, label: value.title, id: value.id };
        })
      );
    } catch (error) {
      console.log("Error while get classification: ", error);
    }
  };

  const handleSelectClassification = (selectedValue) => {
    setSelectedClassifications(selectedValue);
  };

  const handleOpenDrrUpdateForm = () => {
    setOpenDrrUpdateForm(true);
  };

  const handleCloseDrrUpdateForm = () => {
    setOpenDrrUpdateForm(false);
  };

  const getDailySalesForecastDrr = async () => {
    setLoading(true);
    setSalesForecast([]);
    setFilteredSalesForecast([]);
    setDateList([]);
    const postdata = {
      brand: brandName,
      channel_id: selectedChannel.id,
    };
    try {
      const response = await fetchDailySalesForecastDrr(postdata);
      if (response.status === 200) {
        setSalesForecast(response.data.forecast_drr);
        setFilteredSalesForecast(response.data.forecast_drr);
        setDateList(response.data.dates);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while get daily sales forecast drr", error);
      setLoading(false);
    }
  };

  const updateSalesForecast = (gridApi, updateForMonth) => {
    const rowNode = gridApi.getRowNode(rowData.node.id);

    const sku = rowData.data.sku;
    const date = rowData.colDef.field;
    const value = rowData.newValue;
    let forecast = rowData.oldValue[1];

    const listOfDates = [];

    if (updateForMonth) {
      let currentDate = new Date(date);

      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();

      let lastDay = new Date(year, month + 1, 0).getDate();

      for (let day = currentDate.getDate(); day <= lastDay; day++) {
        let currentDate = new Date(year, month, day);
        let formattedDate = `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
        listOfDates.push(formattedDate);
      }
    } else {
      listOfDates.push(date);
    }

    let tempData = updatedSalesForecast;

    listOfDates.forEach((d, i) => {
      rowNode.data[d] = [value, i === 0 ? forecast : rowData.data[d][1], true];

      const skuIndex = tempData.findIndex((item) => item.sku === sku);
      if (skuIndex !== -1) {
        const skuItem = tempData[skuIndex];
        const dateIndex = skuItem.dates.findIndex((item) => item.date === d);
        if (dateIndex !== -1) {
          skuItem.dates[dateIndex].quantity = value;
        } else {
          skuItem.dates.push({ date: d, quantity: value });
        }
      } else {
        tempData.push({ sku, dates: [{ date: d, quantity: value }] });
      }
      gridApi.refreshCells({ rowNodes: [rowNode] });
    });

    setUpdatedSalesForecast(tempData);
    setRowData(null);
  };

  const saveUpdatedSalesForecast = async () => {
    if (!updatedSalesForecast.length) {
      toast.info("There is nothing to update!");
      return;
    }
    setLoading(true);
    const postData = {
      brand: brandName,
      channel_id: selectedChannel.id,
      update_values: updatedSalesForecast,
    };
    try {
      const response = await updateSalesForecastDrr(postData);
      if (response.data.message === "Updated" && response.status === 200) {
        toast.success("DRR updated successfully");
        setUpdatedSalesForecast([]);
        getDailySalesForecastDrr();
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while update sales forecast drr!", error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error while updating the drr!"
      );
      setLoading(false);
    }
  };

  const parseCSV = (text) => {
    const rows = text.split("\n").filter((row) => row.trim() !== "");
    const headers = rows[0]
      .split(",")
      .map((header) => header.trim().replace(/"/g, ""));

    const data = rows.slice(1).map((row) => {
      const values = row
        .split(",")
        .map((value) => value.trim().replace(/"/g, ""));
      return headers.reduce((obj, header, index) => {
        obj[header] = values[index];
        return obj;
      }, {});
    });

    return data;
  };

  const transformData = (csvData) => {
    let data = [];
    csvData.forEach((row) => {
      const sku = row["SKU"];
      const tempObj = {
        sku,
        dates: [],
      };
      for (const [key, value] of Object.entries(row)) {
        if (key !== "SKU") {
          tempObj.dates.push({ date: key, quantity: parseInt(value) });
        }
      }
      data.push(tempObj);
    });
    return data;
  };

  const updateDrrByCsv = async (drrData) => {
    if (!drrData.length) {
      toast.info("Sheet is empty!");
      return;
    }
    setLoading(true);
    const postData = {
      brand: brandName,
      channel_id: selectedChannel.id,
      update_values: drrData,
    };
    try {
      const response = await updateSalesForecastDrr(postData);
      if (response.data.message === "Updated" && response.status === 200) {
        toast.success("DRR updated successfully");
        setUpdatedSalesForecast([]);
        getDailySalesForecastDrr();
      }
      setLoading(false);
      handleCloseDrrUpdateForm();
    } catch (error) {
      console.log("Error while update sales forecast drr!", error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error while updating the drr!"
      );
      setLoading(false);
    }
  };

  const updateSalesForecaseByCsvFile = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    let transformedData;
    reader.onload = function (event) {
      const text = event.target.result;
      const parsedData = parseCSV(text);
      transformedData = transformData(parsedData);
      updateDrrByCsv(transformedData);
    };
    reader.readAsText(drrUpdateCsvFile);
  };

  useEffect(() => {
    getChannels();
    getClassification();
  }, [brandName]);

  useEffect(() => {
    if (brandName && selectedChannel.id) {
      getDailySalesForecastDrr();
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (selectedClassifications.length) {
      const classificationIds = selectedClassifications.map(
        (classification) => classification.id
      );
      const tempSalesForecast = salesForecast.filter((sale) => {
        return classificationIds.includes(
          Object.values(sale)[0].classification
        );
      });
      setFilteredSalesForecast(tempSalesForecast);
    } else {
      setFilteredSalesForecast(salesForecast);
    }
  }, [selectedClassifications, salesForecast]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>DRR Update</h2>
      <DrrUpdateForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
        channels={channels}
        selectedChannel={selectedChannel}
        setSelectedChannel={setSelectedChannel}
        classifications={classifications}
        selectedClassifications={selectedClassifications}
        handleSelectClassification={handleSelectClassification}
      />
      <DrrUpdateTable
        dateList={dateList}
        salesForecast={filteredSalesForecast}
        loading={loading}
        updateSalesForecast={updateSalesForecast}
        saveUpdatedSalesForecast={saveUpdatedSalesForecast}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setRowData={setRowData}
        handleOpenDrrUpdateForm={handleOpenDrrUpdateForm}
      />
      <DrrUpdateByCsvForm
        openDrrUpdateForm={openDrrUpdateForm}
        handleCloseDrrUpdateForm={handleCloseDrrUpdateForm}
        updateSalesForecaseByCsvFile={updateSalesForecaseByCsvFile}
        setDrrUpdateCsvFile={setDrrUpdateCsvFile}
      />
    </div>
  );
};

export default DrrUpdate;
