import ReactEcharts from "echarts-for-react";

const BrandOverviewGraph = ({ loading, inventoryData, tableData }) => {
  let dates = [];
  let inventory = [];
  let forecast = [];
  let safetyStock = [];
  let inventoryNorm = [];
  inventoryData.forEach((data) => {
    let month = new Date(data.inventory_date).getMonth() + 1;
    let year = new Date(data.inventory_date).getFullYear();
    let graphData = tableData.filter(
      (tabData) => tabData.month == month && tabData.year === year
    );
    safetyStock.push(Math.round(graphData[0].safety_stock));
    inventoryNorm.push(Math.round(graphData[0].inventory_norm));
    dates.push(data.inventory_date);
    if (data.inventory_date === new Date().toISOString().split("T")[0]) {
      forecast.push(data.total_quantity);
      inventory.push(data.total_quantity);
    } else if (data.forecast) {
      inventory.push(null);
      forecast.push(data.total_quantity);
    } else {
      forecast.push(null);
      inventory.push(data.total_quantity);
    }
  });

  let option = {
    title: { text: "Daily Inventory" },
    legend: {
      data: [
        "Inventory",
        "Inventory Forecast",
        "Safety Stock",
        "Inventory Norm",
      ],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      type: "category",
      data: dates,
      name: "Date (YYYY-MM-DD)",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 20,
      },
    },
    yAxis: {
      type: "value",
      name: "Qauntity",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 70,
      },
    },
    series: [
      {
        name: "Inventory",
        data: inventory,
        type: "line",
        lineStyle: {
          color: "blue",
        },
        itemStyle: {
          color: "blue",
        },
      },
      {
        name: "Inventory Forecast",
        data: forecast,
        type: "line",
        lineStyle: {
          color: "green",
        },
        itemStyle: {
          color: "green",
        },
      },
      {
        name: "Inventory Norm",
        data: inventoryNorm,
        type: "line",
        showSymbol: false,
        lineStyle: {
          color: "orange",
        },
        itemStyle: {
          color: "orange",
        },
      },
      {
        name: "Safety Stock",
        data: safetyStock,
        type: "line",
        showSymbol: false,
        lineStyle: {
          color: "red",
        },
        itemStyle: {
          color: "red",
        },
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: "400px", marginTop: "4em", padding: "1em 0 0 1em" }}
    />
  );
};

export default BrandOverviewGraph;
