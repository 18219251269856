import React, { useEffect, useRef, useState } from "react";
import styles from "./BrandOverview.module.css";
import BrandOverviewForm from "../../Components/BrandOverview/BrandOverviewForm";
import {
  dateFormat,
  fetchClassification,
  fetchDailyInventory,
} from "../../Utils/helperFunctions";
import BrandOverviewGraph from "../../Components/BrandOverview/BrandOverviewGraph";
import { Box } from "@mui/material";
import BrandOverviewTable from "../../Components/BrandOverview/BrandOverviewTable";

const style = {
  bgcolor: "background.paper",
};

const BrandOverview = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [classifications, setClassifications] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const isBrandChange = useRef(false);
  const isFirstRender = useRef(true);
  let dateStart = new Date();
  let dateEnd = new Date();
  dateStart.setDate(1);
  dateEnd.setDate(1);
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateEnd.setMonth(dateEnd.getMonth() + 1);
  dateEnd = new Date(dateEnd.getFullYear(), dateEnd.getMonth() + 1, 0);
  dateStart = dateFormat(dateStart);
  dateEnd = dateFormat(dateEnd);
  const [dateRange, setDateRange] = useState(`${dateStart}~${dateEnd}`);

  const getClassification = async () => {
    setSelectedClassifications([]);
    const postData = { brand: brandName };
    try {
      const response = await fetchClassification(postData);
      setClassifications(
        response.data.classifications.map((value) => {
          return { value: value.title, label: value.title, id: value.id };
        })
      );
      setSelectedClassifications(
        response.data.classifications
          .filter((value) => value.title.startsWith("Hero"))
          .map((value) => {
            return { value: value.title, label: value.title, id: value.id };
          })
      );
    } catch (error) {
      console.log("Error while get classification: ", error);
    }
  };

  const handleSelectClassification = (selectedValue) => {
    setSelectedClassifications(selectedValue);
  };

  const getDailyInventory = async (brandChange = false) => {
    setLoading(true);
    let [firstDate, lastDate] = dateRange.split("~");
    const postData = {
      brand: brandName,
      date_start: firstDate,
      date_end: lastDate,
      classification: selectedClassifications.map(
        (classification) => classification.value
      ),
    };
    try {
      const response = await fetchDailyInventory(postData);
      if (response.status === 200) {
        setInventoryData(response.data.inventory_data);
        setTableData(response.data.table_data);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while get daily inventory: ", error);
      setLoading(false);
    }
    isBrandChange.current = false;
    isFirstRender.current = false;
  };

  useEffect(() => {
    isBrandChange.current = true;
    isFirstRender.current = true;
    setClassifications([]);
    setInventoryData([]);
    if (brandName) {
      getClassification();
    }
  }, [brandName]);

  useEffect(() => {
    if (brandName && isFirstRender.current && selectedClassifications.length) {
      getDailyInventory(isBrandChange.current);
    } else if (brandName && !isFirstRender.current) {
      getDailyInventory(isBrandChange.current);
    }
  }, [selectedClassifications, dateRange]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Brand Overview</h2>
      <BrandOverviewForm
        brandName={brandName}
        setBrandName={setBrandName}
        dateRange={dateRange}
        setDateRange={setDateRange}
        loading={loading}
        classifications={classifications}
        selectedClassifications={selectedClassifications}
        handleSelectClassification={handleSelectClassification}
      />
      <Box sx={style}>
        <BrandOverviewGraph
          loading={loading}
          inventoryData={inventoryData}
          tableData={tableData}
        />
      </Box>
      <BrandOverviewTable loading={loading} tableData={tableData} />
    </div>
  );
};

export default BrandOverview;
