import React from "react";
import styles from "./Styles.module.css";
import { Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DrrUpdateByCsvForm = ({
  openDrrUpdateForm,
  handleCloseDrrUpdateForm,
  updateSalesForecaseByCsvFile,
  setDrrUpdateCsvFile,
}) => {
  return (
    <Modal
      open={openDrrUpdateForm}
      onClose={handleCloseDrrUpdateForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modalForm}>
        <Typography
          variant="h6"
          align="center"
          sx={{ marginBottom: 4, fontWeight: "bold" }}
        >
          UPDATE SALES FORECAST BY CSV UPLOAD
        </Typography>
        <form
          className={styles.csvForm}
          onSubmit={updateSalesForecaseByCsvFile}
          encType="multipart/form-data"
        >
          <CloseIcon
            className={styles.closeModalIcon}
            onClick={handleCloseDrrUpdateForm}
          />
          <label className={styles.modalCsvLabel}>Upload Csv File</label>
          <input
            placeholder="Upload Csv File"
            className={styles.modalCsvInput}
            type="file"
            accept=".csv"
            name="csvFile"
            onChange={(e) => {
              setDrrUpdateCsvFile(e.target.files[0]);
            }}
          />
          <input
            className={styles.modalCsvUploadSubmitButton}
            type="submit"
            value={"UPLOAD"}
          />
        </form>
      </Box>
    </Modal>
  );
};

export default DrrUpdateByCsvForm;
