import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styles from "./Styles.module.css";
import { Box } from "@mui/system";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const ChannelUpdateForm = ({
  channels,
  b2bTypes,
  updateSelectedChannel,
  setUpdateSelectedChannel,
  ucChannels,
  updateChannelName,
  setUpdateChannelName,
  updateB2bType,
  setUpdateB2bType,
  updateSelectedUcChannels,
  setUpdateSelectedUcChannels,
  updateExistingChannel,
  updateSelectedReferenceChannels,
  setUpdateSelectedReferenceChannels,
}) => {
  const handleUcSelectionChange = (event, options) => {
    setUpdateSelectedUcChannels(options);
  };

  const handleUpdateSelectedChannelChange = (event, options) => {
    setUpdateSelectedReferenceChannels(options);
  };

  return (
    <form className={styles.channelCreateForm} onSubmit={updateExistingChannel}>
      <FormControl fullWidth>
        <InputLabel id="channel_dropdown">Select Channel</InputLabel>
        <Select
          className={styles.createChannelFormInput}
          labelId="channel_dropdown"
          value={updateSelectedChannel}
          label="Select Channel"
          onChange={(e) => setUpdateSelectedChannel(e.target.value)}
          MenuProps={MenuProps}
        >
          {channels.map((elem) => {
            return (
              <MenuItem
                key={elem.id}
                value={elem.id}
                className={styles.dropdownOptions}
              >
                {elem.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Box>
        <TextField
          id="outlined-basic"
          label="Channel Name"
          variant="outlined"
          type="text"
          className={styles.createChannelFormInput}
          value={updateChannelName}
          onChange={(e) => setUpdateChannelName(e.target.value)}
          sx={{ fontSize: "5px", marginTop: 1 }}
        />
      </Box>
      <FormControl fullWidth sx={{ marginTop: 1 }}>
        <InputLabel id="b2b_type_dropdown">Select B2B Type</InputLabel>
        <Select
          className={styles.createChannelFormInput}
          labelId="b2b_type_dropdown"
          id="b2b_types"
          value={updateB2bType}
          label="Select B2B Type"
          onChange={(e) => setUpdateB2bType(e.target.value)}
          MenuProps={MenuProps}
        >
          {b2bTypes.map((elem) => {
            return (
              <MenuItem
                key={elem[0]}
                value={elem[0]}
                className={styles.dropdownOptions}
              >
                {elem[1]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          value={updateSelectedUcChannels}
          options={ucChannels.map((ucChannel) => ucChannel.name)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={(event, options) => handleUcSelectionChange(event, options)}
          sx={{ maxHeight: 150, overflow: "scroll", paddingTop: 1 }}
          ListboxProps={{ style: { maxHeight: "15rem" } }}
          renderOption={(props, option, { selected }) => (
            <li {...props} className={styles.dropdownOptions}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <span className={styles.ucNameWithChannel}>
                {option}{" "}
                <span className={styles.channelName}>
                  (
                  {
                    ucChannels.filter(
                      (ucChannel) => ucChannel.name === option
                    )[0].channel__name
                  }
                  )
                </span>
              </span>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Unicommerce Channel" />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          value={updateSelectedReferenceChannels}
          options={channels.map((channel) => channel.name)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={(event, options) =>
            handleUpdateSelectedChannelChange(event, options)
          }
          sx={{ maxHeight: 150, overflow: "scroll", paddingTop: 1 }}
          ListboxProps={{ style: { maxHeight: "15rem" } }}
          renderOption={(props, option, { selected }) => (
            <li {...props} className={styles.dropdownOptions}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              <span className={styles.ucNameWithChannel}>{option}</span>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Reference Channel" />
          )}
        />
      </FormControl>
      <input
        className={styles.createChannelButton}
        type="submit"
        value={"UPDATE CHANNEL"}
      />
    </form>
  );
};

export default ChannelUpdateForm;
