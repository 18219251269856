import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Styles.module.css";
import { Button, Paper, TextField, Box, Modal, Alert } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SaveIcon from "@mui/icons-material/Save";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DrrUpdateTable = ({
  dateList,
  salesForecast,
  loading,
  updateSalesForecast,
  saveUpdatedSalesForecast,
  openModal,
  setOpenModal,
  setRowData,
  handleOpenDrrUpdateForm,
}) => {
  const gridRef = useRef();
  const [rows, setRows] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [monthName, setMonthName] = useState("");

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    ...dateList.map((date) => ({
      field: date,
      headerName: date,
      flex: 1,
      minWidth: 130,
      editable: true,
      cellEditor: "agNumberCellEditor",
      valueParser: (params) => {
        if (params.newValue) {
          return params.newValue;
        }
        return params.value;
      },
      cellRenderer: (params) => params.value,
      cellStyle: (params) => {
        if (params.value && params.value[1] && params.value[2]) {
          return {
            textAlign: "center",
            backgroundColor: "#C7F6C7",
            color: "#1F51FF",
            fontWeight: "bold",
          };
        } else if (params.value && params.value[2]) {
          return {
            textAlign: "center",
            color: "#1F51FF",
            fontWeight: "bold",
          };
        } else if (params.value && params.value[1]) {
          return {
            textAlign: "center",
            backgroundColor: "#C7F6C7",
          };
        }
        return { textAlign: "center" };
      },
      // Custom comparator to sort numbers properly
      comparator: (valueA, valueB) => {
        const numA = parseFloat(valueA) || 0;
        const numB = parseFloat(valueB) || 0;
        return numA - numB;
      },
    })),
  ];

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Forecast DRR(${brand.name}) ${new Date().toLocaleString()}`,
      processCellCallback: (cellValue) => {
        if (cellValue.accumulatedRowIndex === 0) return cellValue.value;
        else return cellValue.value[0];
      },
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  useEffect(() => {
    setRows(
      salesForecast.map((sales) => {
        const sku = Object.keys(sales)[0];
        const dateAndValues = sales[sku];
        return { sku, ...dateAndValues };
      })
    );
  }, [salesForecast]);

  return (
    <Paper
      sx={{ height: rows.length < 17 ? "" : "700px", margin: "2em 0" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Box className={styles.buttonsBox}>
          <Button
            className="exportButton"
            onClick={onBtnExport}
            startIcon={<SaveAltIcon />}
            disabled={loading}
          >
            Export
          </Button>
          <Button
            className="exportButton"
            onClick={saveUpdatedSalesForecast}
            startIcon={<SaveIcon />}
            disabled={loading}
          >
            Save
          </Button>
          <Button
            className="exportButton"
            onClick={handleOpenDrrUpdateForm}
            startIcon={<SaveIcon />}
            disabled={loading}
          >
            Update By File
          </Button>
        </Box>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 17 ? "autoHeight" : null}
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          rowHeight={35}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
          onGridReady={onGridReady}
          onCellValueChanged={(rowData) => {
            setMonthName(monthNames[new Date(rowData.colDef.field).getMonth()]);
            const rowNode = gridApi.getRowNode(rowData.node.id);
            if (
              rowData.newValue === rowData.oldValue[0] ||
              rowData.newValue === null
            ) {
              rowNode.data[rowData.colDef.field] = rowData.oldValue;
              gridApi.refreshCells({ rowNodes: [rowNode] });
              return;
            }
            setRowData(rowData);
            setOpenModal(true);
          }}
          getRowStyle={(params) => {
            if (params.data.id === "total") {
              return {
                background: "#f2f1ed",
                fontSize: "15px",
                fontWeight: "bold",
              };
            }
            return null;
          }}
        />
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Alert
            severity="info"
            sx={{
              fontWeight: "bold",
              color: "grey",
              textAlign: "center",
              "& .MuiAlert-icon": {
                margin: "0 auto",
              },
            }}
          >
            Do you want to update this value for all dates in the{" "}
            <span style={{ color: "black" }}>{monthName}</span> month?
          </Alert>
          <Box className={styles.confirmationButtonBox}>
            <button
              className={styles.submitButton}
              onClick={() => {
                setOpenModal(false);
                updateSalesForecast(gridApi, false);
                setMonthName("");
              }}
            >
              NO
            </button>
            <button
              type="submit"
              className={styles.submitButton}
              onClick={() => {
                setOpenModal(false);
                updateSalesForecast(gridApi, true);
                setMonthName("");
              }}
            >
              YES
            </button>
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};

export default DrrUpdateTable;
