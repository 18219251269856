import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Styles.module.css";

const table_cell_style = { paddingTop: 10, paddingBottom: 10 };
const month_num_fy = {
  Apr: 0,
  May: 1,
  Jun: 2,
  Jul: 3,
  Aug: 4,
  Sep: 5,
  Oct: 6,
  Nov: 7,
  Dec: 8,
  Jan: 9,
  Feb: 10,
  Mar: 11,
};
const month_name = [
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
  "jan",
  "feb",
  "mar",
];

const PlannedVsActualTable = ({ reports, loading }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const curr_month = new Date().toLocaleString("en-US", { month: "short" });
  const month_for_avg_actuals = month_name.slice(0, month_num_fy[curr_month]);
  const month_for_sugg_actual = month_name.slice(
    month_num_fy[curr_month] + 1,
    12
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columnsHead = [
    {
      id: "channel",
      label: "",
      minWidth: 170,
      align: "center",
      colSpan: 1,
      width: 200,
    },
    {
      id: "apr",
      label: "April",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "may",
      label: "May",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "jun",
      label: "June",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "jul",
      label: "July",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "aug",
      label: "August",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "sep",
      label: "September",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "oct",
      label: "October",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "nov",
      label: "November",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "dec",
      label: "December",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "jan",
      label: "January",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "feb",
      label: "February",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
    {
      id: "mar",
      label: "March",
      minWidth: 100,
      align: "center",
      colSpan: 3,
      width: 200,
    },
  ];

  const columnsSubHead = [
    {
      id: "channel",
      label: "Channel",
      align: "center",
    },
    {
      id: "apr_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "apr_actual",
      label: month_for_sugg_actual.includes("apr")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "apr_percentage",
      label: month_for_sugg_actual.includes("apr")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "may_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "may_actual",
      label: month_for_sugg_actual.includes("may")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "may_percentage",
      label: month_for_sugg_actual.includes("may")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "jun_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "jun_actual",
      label: month_for_sugg_actual.includes("jun")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "jun_percentage",
      label: month_for_sugg_actual.includes("jun")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "jul_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "jul_actual",
      label: month_for_sugg_actual.includes("jul")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "jul_percentage",
      label: month_for_sugg_actual.includes("jul")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "aug_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "aug_actual",
      label: month_for_sugg_actual.includes("aug")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "aug_percentage",
      label: month_for_sugg_actual.includes("aug")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "sep_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "sep_actual",
      label: month_for_sugg_actual.includes("sep")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "sep_percentage",
      label: month_for_sugg_actual.includes("sep")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "oct_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "oct_actual",
      label: month_for_sugg_actual.includes("oct")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "oct_percentage",
      label: month_for_sugg_actual.includes("oct")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "nov_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "nov_actual",
      label: month_for_sugg_actual.includes("nov")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "nov_percentage",
      label: month_for_sugg_actual.includes("nov")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "dec_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "dec_actual",
      label: month_for_sugg_actual.includes("dec")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "dec_percentage",
      label: month_for_sugg_actual.includes("dec")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "jan_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "jan_actual",
      label: month_for_sugg_actual.includes("jan")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "jan_percentage",
      label: month_for_sugg_actual.includes("jan")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "feb_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "feb_actual",
      label: month_for_sugg_actual.includes("feb")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "feb_percentage",
      label: month_for_sugg_actual.includes("feb")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
    {
      id: "mar_planned",
      label: "Planned Gross Units",
      align: "center",
    },
    {
      id: "mar_actual",
      label: month_for_sugg_actual.includes("mar")
        ? "Suggested LE Gross Units"
        : "Actual Gross Units",
      align: "center",
    },
    {
      id: "mar_percentage",
      label: month_for_sugg_actual.includes("mar")
        ? "Division Percentage Over Plan"
        : "Percentage Attainment",
      align: "center",
    },
  ];

  reports.forEach((report) => {
    let total_actual = 0;
    let total_planned = 0;
    month_for_avg_actuals.forEach((month) => {
      total_actual += report.actual[month] || 0;
      total_planned += report.planned[month] || 0;
    });
    let percentage = Math.round(
      total_planned ? (total_actual / total_planned) * 100 : 100
    );
    month_for_sugg_actual.forEach((month) => {
      report.percentage[month] = percentage;
      report.actual[month] =
        ((report.planned[month] || 0) * (report.percentage[month] || 0)) / 100;
    });
  });

  const rows = reports.map((report, index) => {
    return {
      id: index + 1,
      channel: report.channel_name,
      apr_planned: Math.round(report.planned.apr || 0),
      apr_actual: Math.round(report.actual.apr || 0),
      apr_percentage: Math.round(report.percentage.apr || 0),
      may_planned: Math.round(report.planned.may || 0),
      may_actual: Math.round(report.actual.may || 0),
      may_percentage: Math.round(report.percentage.may || 0),
      jun_planned: Math.round(report.planned.jun || 0),
      jun_actual: Math.round(report.actual.jun || 0),
      jun_percentage: Math.round(report.percentage.jun || 0),
      jul_planned: Math.round(report.planned.jul || 0),
      jul_actual: Math.round(report.actual.jul || 0),
      jul_percentage: Math.round(report.percentage.jul || 0),
      aug_planned: Math.round(report.planned.aug || 0),
      aug_actual: Math.round(report.actual.aug || 0),
      aug_percentage: Math.round(report.percentage.aug || 0),
      sep_planned: Math.round(report.planned.sep || 0),
      sep_actual: Math.round(report.actual.sep || 0),
      sep_percentage: Math.round(report.percentage.sep || 0),
      oct_planned: Math.round(report.planned.oct || 0),
      oct_actual: Math.round(report.actual.oct || 0),
      oct_percentage: Math.round(report.percentage.oct || 0),
      nov_planned: Math.round(report.planned.nov || 0),
      nov_actual: Math.round(report.actual.nov || 0),
      nov_percentage: Math.round(report.percentage.nov || 0),
      dec_planned: Math.round(report.planned.dec || 0),
      dec_actual: Math.round(report.actual.dec || 0),
      dec_percentage: Math.round(report.percentage.dec || 0),
      jan_planned: Math.round(report.planned.jan || 0),
      jan_actual: Math.round(report.actual.jan || 0),
      jan_percentage: Math.round(report.percentage.jan || 0),
      feb_planned: Math.round(report.planned.feb || 0),
      feb_actual: Math.round(report.actual.feb || 0),
      feb_percentage: Math.round(report.percentage.feb || 0),
      mar_planned: Math.round(report.planned.mar || 0),
      mar_actual: Math.round(report.actual.mar || 0),
      mar_percentage: Math.round(report.percentage.mar || 0),
    };
  });

  const totalRowDict = {
    apr_planned: 0,
    may_planned: 0,
    jun_planned: 0,
    jul_planned: 0,
    aug_planned: 0,
    sep_planned: 0,
    oct_planned: 0,
    nov_planned: 0,
    dec_planned: 0,
    jan_planned: 0,
    feb_planned: 0,
    mar_planned: 0,
    apr_actual: 0,
    may_actual: 0,
    jun_actual: 0,
    jul_actual: 0,
    aug_actual: 0,
    sep_actual: 0,
    oct_actual: 0,
    nov_actual: 0,
    dec_actual: 0,
    jan_actual: 0,
    feb_actual: 0,
    mar_actual: 0,
    apr_percentage: 0,
    may_percentage: 0,
    jun_percentage: 0,
    jul_percentage: 0,
    aug_percentage: 0,
    sep_percentage: 0,
    oct_percentage: 0,
    nov_percentage: 0,
    dec_percentage: 0,
    jan_percentage: 0,
    feb_percentage: 0,
    mar_percentage: 0,
  };
  rows.forEach((row) => {
    totalRowDict["apr_planned"] += row["apr_planned"] ? +row["apr_planned"] : 0;
    totalRowDict["may_planned"] += row["may_planned"] ? +row["may_planned"] : 0;
    totalRowDict["jun_planned"] += row["jun_planned"] ? +row["jun_planned"] : 0;
    totalRowDict["jul_planned"] += row["jul_planned"] ? +row["jul_planned"] : 0;
    totalRowDict["aug_planned"] += row["aug_planned"] ? +row["aug_planned"] : 0;
    totalRowDict["sep_planned"] += row["sep_planned"] ? +row["sep_planned"] : 0;
    totalRowDict["oct_planned"] += row["oct_planned"] ? +row["oct_planned"] : 0;
    totalRowDict["nov_planned"] += row["nov_planned"] ? +row["nov_planned"] : 0;
    totalRowDict["dec_planned"] += row["dec_planned"] ? +row["dec_planned"] : 0;
    totalRowDict["jan_planned"] += row["jan_planned"] ? +row["jan_planned"] : 0;
    totalRowDict["feb_planned"] += row["feb_planned"] ? +row["feb_planned"] : 0;
    totalRowDict["mar_planned"] += row["mar_planned"] ? +row["mar_planned"] : 0;
    totalRowDict["apr_actual"] += row["apr_actual"] ? +row["apr_actual"] : 0;
    totalRowDict["may_actual"] += row["may_actual"] ? +row["may_actual"] : 0;
    totalRowDict["jun_actual"] += row["jun_actual"] ? +row["jun_actual"] : 0;
    totalRowDict["jul_actual"] += row["jul_actual"] ? +row["jul_actual"] : 0;
    totalRowDict["aug_actual"] += row["aug_actual"] ? +row["aug_actual"] : 0;
    totalRowDict["sep_actual"] += row["sep_actual"] ? +row["sep_actual"] : 0;
    totalRowDict["oct_actual"] += row["oct_actual"] ? +row["oct_actual"] : 0;
    totalRowDict["nov_actual"] += row["nov_actual"] ? +row["nov_actual"] : 0;
    totalRowDict["dec_actual"] += row["dec_actual"] ? +row["dec_actual"] : 0;
    totalRowDict["jan_actual"] += row["jan_actual"] ? +row["jan_actual"] : 0;
    totalRowDict["feb_actual"] += row["feb_actual"] ? +row["feb_actual"] : 0;
    totalRowDict["mar_actual"] += row["mar_actual"] ? +row["mar_actual"] : 0;
  });

  totalRowDict["apr_percentage"] = Math.round(
    totalRowDict["apr_planned"]
      ? (totalRowDict["apr_actual"] / totalRowDict["apr_planned"]) * 100
      : 100
  );
  totalRowDict["may_percentage"] = Math.round(
    totalRowDict["may_planned"]
      ? (totalRowDict["may_actual"] / totalRowDict["may_planned"]) * 100
      : 100
  );
  totalRowDict["jun_percentage"] = Math.round(
    totalRowDict["jun_planned"]
      ? (totalRowDict["jun_actual"] / totalRowDict["jun_planned"]) * 100
      : 100
  );
  totalRowDict["jul_percentage"] = Math.round(
    totalRowDict["jul_planned"]
      ? (totalRowDict["jul_actual"] / totalRowDict["jul_planned"]) * 100
      : 100
  );
  totalRowDict["aug_percentage"] = Math.round(
    totalRowDict["aug_planned"]
      ? (totalRowDict["aug_actual"] / totalRowDict["aug_planned"]) * 100
      : 100
  );
  totalRowDict["sep_percentage"] = Math.round(
    totalRowDict["sep_planned"]
      ? (totalRowDict["sep_actual"] / totalRowDict["sep_planned"]) * 100
      : 100
  );
  totalRowDict["oct_percentage"] = Math.round(
    totalRowDict["oct_planned"]
      ? (totalRowDict["oct_actual"] / totalRowDict["oct_planned"]) * 100
      : 100
  );
  totalRowDict["nov_percentage"] = Math.round(
    totalRowDict["nov_planned"]
      ? (totalRowDict["nov_actual"] / totalRowDict["nov_planned"]) * 100
      : 100
  );
  totalRowDict["dec_percentage"] = Math.round(
    totalRowDict["dec_planned"]
      ? (totalRowDict["dec_actual"] / totalRowDict["dec_planned"]) * 100
      : 100
  );
  totalRowDict["jan_percentage"] = Math.round(
    totalRowDict["jan_planned"]
      ? (totalRowDict["jan_actual"] / totalRowDict["jan_planned"]) * 100
      : 100
  );
  totalRowDict["feb_percentage"] = Math.round(
    totalRowDict["feb_planned"]
      ? (totalRowDict["feb_actual"] / totalRowDict["feb_planned"]) * 100
      : 100
  );
  totalRowDict["mar_percentage"] = Math.round(
    totalRowDict["mar_planned"]
      ? (totalRowDict["mar_actual"] / totalRowDict["mar_planned"]) * 100
      : 100
  );

  rows.push({ id: "total", channel: "Total", ...totalRowDict });

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table
          stickyHeader
          aria-label=""
          sx={
            loading || rows.length === 0 ? { height: 300 } : { maxHeight: 760 }
          }
        >
          <TableHead style={{ height: "10px !important" }}>
            <TableRow style={{ height: "10px !important" }}>
              {columnsHead.map((column) => {
                return (
                  <TableCell
                    className={
                      column.id === "channel"
                        ? styles.channelHeaderTableCell
                        : styles.headerTableCell
                    }
                    key={column.id}
                    align={column.align}
                    colSpan={column.colSpan}
                    style={{
                      height: "10px !important",
                      minWidth: column.width,
                      backgroundColor: "black",
                      color: "white",
                      fontWeight: "bold",
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {columnsSubHead.map((column) => {
                const forecast =
                  column.label === "Suggested LE Gross Units" ||
                  column.label === "Division Percentage Over Plan";
                return (
                  <TableCell
                    className={
                      column.id === "channel"
                        ? styles.channelHeaderTableCell
                        : styles.headerTableCell
                    }
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: forecast ? "#3B3B3B" : "black",
                      color: "white",
                      fontWeight: "bold",
                      paddingTop: 6,
                      paddingBottom: 6,
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              {new Array(3).fill(1).map((row, index) => {
                return (
                  <TableRow key={row + index}>
                    {new Array(37).fill(1).map((column, index) => {
                      return (
                        <TableCell
                          key={column + index}
                          className={styles.tableCell}
                        >
                          <Box>
                            <Skeleton />
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : rows.length === 0 ? (
            <Box sx={{ position: "relative", top: "50%", left: "250%" }}>
              No rows
            </Box>
          ) : (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      className={row.channel === "Total" ? styles.totalRow : ""}
                    >
                      <TableCell
                        className={styles.channelTableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.channel}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.apr_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.apr_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.apr_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.may_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.may_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.may_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jun_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jun_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jun_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jul_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jul_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jul_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.aug_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.aug_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.aug_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.sep_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.sep_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.sep_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.oct_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.oct_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.oct_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.nov_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.nov_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.nov_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.dec_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.dec_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.dec_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jan_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jan_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.jan_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.feb_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.feb_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.feb_percentage}%
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.mar_planned}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.mar_actual}
                      </TableCell>
                      <TableCell
                        className={styles.tableCell}
                        style={table_cell_style}
                        align="center"
                      >
                        {row.mar_percentage}%
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 500, 1000]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default PlannedVsActualTable;
