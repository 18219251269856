import React from "react";
import styles from "./Styles.module.css";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "25px",
    maxHeight: "100px",
    overflowX: "scroll",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "25px",
    margin: "auto",
  }),
};

const DrrUpdateForm = ({
  brandName,
  setBrandName,
  loading,
  channels,
  selectedChannel,
  setSelectedChannel,
  classifications,
  selectedClassifications,
  handleSelectClassification,
}) => {
  return (
    <div className={styles.formBox}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <div className={styles.inputBox}>
        <label className={styles.label}>Channels</label>
        <Select
          className={styles.selectInput}
          styles={customStyles}
          placeholder="Select channel"
          value={selectedChannel}
          options={channels}
          onChange={(channel) => {
            setSelectedChannel(channel);
          }}
          isDisabled={loading}
        />
      </div>
      <div className={styles.inputBox}>
        <label className={styles.label}>Classification</label>
        <Select
          styles={customStyles}
          placeholder="Select Classifications"
          closeMenuOnSelect={false}
          isMulti
          options={classifications}
          value={selectedClassifications}
          onChange={handleSelectClassification}
          className={styles.selectInput}
          isDisabled={loading}
        />
      </div>
    </div>
  );
};

export default DrrUpdateForm;
