import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// Table head with month names
const tableHeaders = [
  "channel",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
  "january",
  "february",
  "march",
];

export const monthNames = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const base_url = process.env.REACT_APP_HOST;

// Checking authentication
export const isAuthenticated = async () => {
  try {
    const response = await axios.get(`${base_url}/api/check-authentication/`, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await axios.get(`${base_url}/api/logout/`, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Method for fetching brands
export const fetchBrands = async () => {
  try {
    const response = await axios.get(`${base_url}/api/brand/`);
    return response;
  } catch (error) {
    throw error;
  }
};

// Method for fetching sales prediction
export const fetchSalesPrediction = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/sales-projection/get/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Method to reset changes on revenues
export const resetRevenuesChanges = (setRevenues) => {
  setRevenues([]);
};

// Method to handle cell edits
export const handleCellEdit = (event, setRevenues, channel, monthName) => {
  if (
    event.target.attributes.name &&
    event.target.attributes.name.nodeValue == "confidence_score"
  ) {
    const confidence_score = event.target.innerText;
    const quantity = event.target.parentNode.parentNode.childNodes[0].innerText;

    if (+confidence_score > 200) {
      toast(`Confidence score can not be more than 200`);
    }

    setRevenues((prevData) => {
      const existingDataIndex = prevData.findIndex(
        (data) => data.channel == channel
      );

      // If channel already exist, update the existing data
      if (existingDataIndex !== -1) {
        const updatedData = [...prevData];

        if (!updatedData[existingDataIndex][monthName]) {
          updatedData[existingDataIndex][monthName] = {};
        }
        updatedData[existingDataIndex][monthName]["value"] = quantity;
        updatedData[existingDataIndex][monthName]["confidence_score"] =
          confidence_score;

        return updatedData;
      } else {
        return [
          ...prevData,
          {
            channel: channel,
            [monthName]: {
              value: quantity,
              confidence_score,
            },
          },
        ];
      }
    });
  } else {
    const quantity = event.target.innerText;
    const confidence_score =
      event.target.parentNode.childNodes[1].childNodes[0].innerText;

    setRevenues((prevData) => {
      const existingDataIndex = prevData.findIndex(
        (data) => data.channel == channel
      );

      // If channel already exist, update the existing data
      if (existingDataIndex !== -1) {
        const updatedData = [...prevData];
        if (!updatedData[existingDataIndex][monthName]) {
          updatedData[existingDataIndex][monthName] = {};
        }
        updatedData[existingDataIndex][monthName]["value"] = quantity;
        updatedData[existingDataIndex][monthName]["confidence_score"] =
          confidence_score;

        return updatedData;
      } else {
        return [
          ...prevData,
          {
            channel: channel,
            [monthName]: {
              value: quantity,
              confidence_score,
            },
          },
        ];
      }
    });
  }
};

// Method to save revenue changes
export const saveRevenueChanges = async (
  setRevenues,
  revenues,
  brandName,
  financialYear
) => {
  const data = {
    brand: brandName,
    financial_year: financialYear,
    quantities: revenues,
  };
  try {
    const response = await axios.post(
      `${base_url}/api/sales-projection/post/`,
      data,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    resetRevenuesChanges(setRevenues);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchInventoryDetails = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/inventory/product/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchInventory = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/inventory/current/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchAdhocReplenishment = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/replenishments/unplanned/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchBrandConfig = async (brandConfigUrl) => {
  try {
    const response = await axios.get(brandConfigUrl);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateOrCreate = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/brand/configuration/post/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchDailySales = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/sales/daily/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchSalesInventoryDetails = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/sales/daily/product/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchWorkingCapitals = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/working-capital/get/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchReplenishmentPlan = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/replenishments/planned/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const signin = async (username, password) => {
  const postData = {
    username,
    password,
  };
  try {
    const response = await axios.post(`${base_url}/api/login/`, postData, {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchPurchaseOrders = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/purchase-orders/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchOutOfStockLosses = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/out-of-stock-loss/history/`,
      postData,
      { headers: { "X-CSRFToken": Cookies.get("csrftoken") } }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchMoq = async (brandName) => {
  const postData = {
    brand: brandName,
  };
  try {
    const response = await axios.post(`${base_url}/api/moq/get/`, postData, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const productBulkUpdate = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/products/bulk-update/`,
      postData,
      {
        headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchProducts = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/products/get/`,
      postData,
      { headers: { "X-CSRFToken": Cookies.get("csrftoken") } }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const productCsvUpload = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/products/csv-upload/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchChannels = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/channels/get/`,
      postData,
      { headers: { "X-CSRFToken": Cookies.get("csrftoken") } }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createChannel = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/channel/create/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateChannel = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/channel/update/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch report demand (planned/actual)
export const fetchReportsDemand = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/reports/demand/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch inventory health report
export const fetchInventoryHealthReports = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/reports/inventory/health/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch classification for brand
export const fetchClassification = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/product/classifications/list/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Fetch Inventory Norm for brand
export const fetchInventoryNorm = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/inventory-norm/get/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const classificationBulkUpdate = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/product/classifications/bulk-update/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateInventoryNorm = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/inventory-norm/post/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchInventoryScore = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/inventory-score/get/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const dateFormat = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const currentDate = date.getDate();
  return `${year}-${month}-${currentDate}`;
};

export const fetchDailySalesForecastDrr = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/sales-forecast/get/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSalesForecastDrr = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/sales-forecast/post/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchDailyInventory = async (postData) => {
  try {
    const response = await axios.post(
      `${base_url}/api/daily-inventory/get/`,
      postData,
      {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
